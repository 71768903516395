<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular py-3">
        NID OCR Billing Receipt
        <sup>
          <v-chip v-if="receipt.status" small>{{ receipt.status }}</v-chip>
        </sup>
      </v-toolbar-title>

      <v-spacer />
      <v-btn outlined @click="() => showPaymentDialog()" v-if="canShowPayment">
        Make Payment
      </v-btn>
    </v-toolbar>

    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="tableHeaders"
          :items="tableData"
          item-key="id"
          class="elevation-2"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.month`]="{ item }">
            {{ getMonthName(item) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="receipt.dailyBills">
      <v-col cols="12">
        <v-card light class="pa-3">
          <template v-for="data in additionalData">
            <v-divider :key="data.label" v-if="data.separator" />
            <div class="receipt-data" :key="data.label" v-else>
              <div class="label">{{ data.label }}</div>
              <div class="value">{{ data.value }}</div>
            </div>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="grey lighten-4">Make Payment</v-card-title>
        <form @submit.stop.prevent="makePayment">
          <v-card-text>
            <v-text-field
              type="number"
              v-model="paymentAmount"
              min="0"
              :max="receipt.remainingAmount"
              hide-details
              outlined
              label="Payment amount"
              required
              class="mb-3"
            />
            <div class="caption grey--text">Payment Date</div>
            <v-date-picker
              v-model="paymentDate"
              title="Payment Date"
              full-width
              elevation="2"
              :max="currentDate"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions class="grey lighten-3 py-2 px-4">
            <v-spacer />
            <v-btn type="submit" color="primary" class="px-10">Submit</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as api from "@/constants/api";
import moment from "moment";

export default {
  metaInfo: { title: "NID OCR Billing" },
  data() {
    return {
      loading: false,
      receipt: {},
      dialog: false,
      paymentAmount: null,
      paymentDate: null,
    };
  },
  computed: {
    userId() {
      return Number(this.$route.query.userId) || this.$auth.user.id;
    },
    year() {
      return Number(this.$route.query.year) || new Date().getFullYear();
    },
    month() {
      return Number(this.$route.query.month) || new Date().getMonth();
    },
    tableHeaders: () => [
      { text: "Year", value: "year" },
      { text: "Month", value: "month" },
      { text: "Day", value: "day" },
      { text: "Count", value: "total" },
    ],
    tableData() {
      return this.receipt.dailyBills || [];
    },
    currentDate() {
      return new Date().toISOString().split("T")[0];
    },
    additionalData() {
      if (!this.receipt) return [];
      return [
        {
          label: "Total calls",
          value: this.receipt.total,
        },
        {
          label: "Free calls",
          value: this.receipt.free,
        },
        { separator: true },
        {
          label: "Chargeable calls",
          value: this.receipt.chargeable,
        },
        {
          label: "Charge per call",
          value: this.formatCurrency(this.receipt.chargePerCall),
        },
        {
          label: "Charge",
          value: this.formatCurrency(this.receipt.charge),
        },
        { separator: true },
        {
          label: "Recurring charge",
          value: this.formatCurrency(this.receipt.recurringCharge),
        },
        {
          label: "Total charge",
          value: this.formatCurrency(this.receipt.totalCharge),
        },
        { separator: true },
        {
          label: "Payment status",
          value: this.receipt.status,
        },
        {
          label: "Paid amount",
          value: this.formatCurrency(this.receipt.paidAmount),
        },
        {
          label: "Pending amount",
          value: this.formatCurrency(this.receipt.remainingAmount),
        },
      ];
    },
    canShowPayment() {
      return (
        this.verifyPrivilege(api.NID_OCR_BILLING_PAYMENT) &&
        this.receipt.remainingAmount &&
        this.receipt.remainingAmount > 0
      );
    },
  },
  mounted() {
    this.fetchBillingList();
  },
  methods: {
    async fetchBillingList() {
      try {
        this.loading = true;
        const resp = await this.$axios.get(api.NID_OCR_BILLING_GET, {
          params: {
            userId: this.userId,
            year: this.year,
            month: this.month,
          },
        });
        resp.data.data.dailyBills.forEach((item) => {
          item.id = Math.random();
        });
        this.receipt = resp.data.data;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    getMonthName({ year, month }) {
      return moment(new Date(year, month - 1)).format("MMMM");
    },
    formatCurrency(number) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "BDT" }).format(number);
    },
    showPaymentDialog() {
      this.paymentAmount = this.receipt.remainingAmount;
      this.paymentDate = this.currentDate;
      this.dialog = true;
    },
    async makePayment() {
      try {
        await this.$axios.post(api.NID_OCR_BILLING_PAYMENT, null, {
          params: {
            userId: this.userId,
            year: this.year,
            month: this.month,
            amount: Number(this.paymentAmount),
            paymentDate: new Date(this.paymentDate).getTime() / 1000,
          },
        });
        this.fetchBillingList();
        this.dialog = false;
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
  },
};
</script>

<style lang="scss">
.receipt-data {
  color: black;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;

  .label {
    text-align: right;
    width: 50%;
    padding: 10px;
  }
  .value {
    text-align: left;
    width: 50%;
    padding: 10px;
    font-weight: 500;
  }
}
</style>
